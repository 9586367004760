import store from "../store";
import {ALLOWED_ACCESS, LOGIN_ERROR_STATE, LOGIN_INITIAL_STATE} from "../store/types";
import API from "./api";

export const AuthService = {
  loginAdmin: async (dd) => {
    store.dispatch({type: LOGIN_INITIAL_STATE, payload: true});

    try {
      const data = await API.post(`/api/v1/account/login`, dd);
      return data?.data;
    } catch (err) {
      throw err;
    }
  },

  getAdminProfile: async (dd) => {
    store.dispatch({type: LOGIN_INITIAL_STATE, payload: true});
    try {
      const data = await API.get(`/api/v1/account/get-admin-profile/${dd}`);
      return data?.data;
    } catch (err) {
      throw err;
    }
  },

  generateGoogleAuthBarcode: async (dd) => {
    try {
      const data = await API.post(`/api/v1/account/generate-auth`, dd);
      return data?.data;
    } catch (err) {
      throw err;
    }
  },
  confirmGoogleAuth: async (dd) => {
    try {
      const data = await API.post(`/api/v1/account/complete-auth-setup`, dd);
      return data?.data;
    } catch (err) {
      throw err;
    }
  },
  getAdminDetails: async (dd) => {
    setHeaders(dd);
    try {
      const data = await API.get(`/api/v1/admin/profile/get-admin-details`);
      return data?.data;
    } catch (err) {
      throw err;
    }
  },
  getAdminDetailsWithToken: async (dd) => {
    try {
      const data = await API.get(`/api/v1/admin/profile/get-admin-details`);
      return data?.data;
    } catch (err) {
      throw err;
    }
  },
  changePassword: async (dd) => {
    try {
      const data = await API.post(`/api/v1/account/change-password`, dd);
      return data?.data;
    } catch (err) {
      throw err;
    }
  },
};

const setHeaders = (param) => {
  API.defaults.headers["Authorization"] = `Bearer ${param.token}`;
  localStorage.setItem("token", param.token);
  localStorage.setItem("isLoggedIn", true);
  store.dispatch({type: ALLOWED_ACCESS, payload: true});
};
