import {useHistory} from "react-router-dom";
import "../../../styles/googleConfirmationModal.scss";
import {Success} from "../../../utils/imagePath";
const GoogleConfirmation = ({setStep, stepData}) => {
  const {push} = useHistory();
  return (
    <div className="googleComfirmationWrapper">
      <div className="googleComfirmationWrapper__ImageSection">
        <img src={Success} alt="confirmation" />
      </div>
      <div className="googleComfirmationWrapper__Details">
        <p>Authentication Completed</p>
      </div>
      <div className="googleComfirmationWrapper__Action">
        <button onClick={() => setStep(3)}>Change Password</button>
      </div>
    </div>
  );
};

export default GoogleConfirmation;
