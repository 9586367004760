import React, {memo} from "react";
import {Route, Redirect} from "react-router-dom";

const ProtectedRoute = ({component, ...props}) => {
  const isLoggedIn = JSON.parse(localStorage.getItem("isLoggedIn"));

  return isLoggedIn ? <Route {...props} component={component} /> : <Redirect to="/" />;
};

export default ProtectedRoute;
