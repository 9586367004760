import "react-toastify/dist/ReactToastify.css";
import ProtectedRoute from "./screens/ProtectedRoute";
import Login from "./screens/Login";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import {Suspense, useEffect, lazy} from "react";
import "./App.scss";
import ForgotPassword from "./screens/ForgotPassword";
import {ToastContainer} from "react-toastify";
import {Loader3} from "./components/common/Loader";

const Home = lazy(() => import("./screens/Home"));
function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <Router>
      <Suspense fallback={<OurLoading />}>
        <div className="App">
          <Switch>
            <Route exact path="/" component={Login} />
            <Route path="/forgot-password" component={ForgotPassword} />
            <ProtectedRoute path="/app" component={Home} />

            <Route render={() => <h1>404 page not found</h1>} />
          </Switch>
          <ToastContainer
            position="top-center"
            autoClose={2500}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
          />
        </div>
      </Suspense>
    </Router>
  );
}

export const OurLoading = () => {
  return (
    <>
      <div className="home-container">
        <>
          <div className="main-content">
            <div className="pages">
              <div className="contents">
                {/* <TopBar /> */}
                <div className="middle" style={{margin: "auto"}}>
                  <Loader3 />
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default App;
