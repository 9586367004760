import axios from "axios";
import store from "../store";
export const IMAGE_BASE_URL = `https://oyola.s3.amazonaws.com/`;

const APIs = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    apiKey: process.env.REACT_APP_API_KEY,

    Authorization: localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : undefined,
  },
});

//Checks for internet connection
APIs.interceptors.request.use(function (config) {
  if (navigator.onLine) {
    return config;
  } else {
    // toast("No internet connection", {
    //   toastId: "network error",
    // });
    console.log("no network");
  }
});

APIs.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    if (
      // err.response.status === 501 ||
      err.message.message === "TokenExpiredError: jwt expired" ||
      err.message.message === "jwt expired" ||
      err.message.name === "TokenExpiredError" ||
      err.message.message.message === "jwt expired" ||
      err.message.message.message === "dev auth failed"
    ) {
      localStorage.clear();
      window.location = "/";
    }

    throw err?.response?.data;
    // if (err.response?.status === 501) {
    // if (err.response.data.message === "Network error!") {
    // store.dispatch(loginError(err.response.data.message));
    // store.dispatch(loginErrorAction(err.response.data.message));
    // store.dispatch(loader(0));
    // store.dispatch(logout());
    // }
    // }
  }
);

export default APIs;
