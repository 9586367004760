import "../../styles/loader.scss";

const Loader = ({style}) => {
  return (
    <div className="loader__container" style={{...style}}>
      <div className="loader" />
    </div>
  );
};

const Loader3 = () => {
  return (
    <div className="loader__container">
      <div className="loader" />
    </div>
  );
};
export const SmallLoader = () => {
  return <div className="loader2" />;
};

const Loader2 = () => {
  return (
    <div style={{}}>
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <span className="loading__text">loading</span>
    </div>
  );
};

const Loader4 = () => {
  return (
    <div className="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

const Loader5 = () => {
  return (
    <div className="lds-ellipsismain">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export {Loader, Loader2, Loader3, Loader4, Loader5};
