import {USER_PROFILE_ERROR, USER_PROFILE_LOADER, USER_PROFILE_DETAILS} from "../types";

const initialState = {
  userProfileDetails: null,
  error: null,
  loading: true,
};

const userProfileReducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case USER_PROFILE_DETAILS: {
      return {
        ...state,
        userProfileDetails: payload,
      };
    }

    case USER_PROFILE_LOADER: {
      return {
        ...state,
        loading: payload,
      };
    }

    case USER_PROFILE_ERROR: {
      return {
        ...state,
        error: payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default userProfileReducer;
