import {
  GET_TRADE_DEFAULT,
  GET_TRADE_ERROR,
  GET_TRADE_INITIAL,
  GET_TRADE_RESULT,
  DISPUTE_TABLE_SKIP,
  DISPUTE_TABLE_LIMIT,
} from "../types";

const initialState = {
  error: null,
  response: null,
  loading: false,
  skip: 0,
  fskip: 0,
  limit: 20,
};

const disputeTableReducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case GET_TRADE_RESULT: {
      return {
        ...state,
        response: payload,
      };
    }

    case GET_TRADE_INITIAL: {
      return {
        ...state,
        loading: payload,
      };
    }

    case GET_TRADE_ERROR: {
      return {
        ...state,
        error: payload,
        loading: false,
      };
    }

    case GET_TRADE_DEFAULT: {
      return {
        ...state,
        ...initialState,
      };
    }

    case DISPUTE_TABLE_SKIP: {
      return {
        ...state,
        skip: payload,
      };
    }

    case DISPUTE_TABLE_LIMIT: {
      return {
        ...state,
        limit: payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default disputeTableReducer;
