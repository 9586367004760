import {
  TRANSACTION_HISTORY_RESPONSE,
  TRANSACTION_HISTORY_SKIP,
  TRANSACTION_HISTORY_LIMIT,
  TRANSACTION_HISTORY_LORDER,
  TRANSACTION_TYPE,
  TRANSACTION_TOKENSYMBOL,
  TRANSACTION_STATUS,
  TRANSAC_SWITCHER,
  HISTORY_VIEW_DETAILS,
  SEARCH_VALUE,
} from "../types";

const initialState = {
  response: [],
  skip: 0,
  limit: 20,
  loader: true,
  transactionType: "all",
  transactionTokenSymbol: "all",
  transactionStatus: "all",
  transacSwitcher: "Crypto",
  historyViewDetails: {},
  searchValue: "",
};

const TransactionReducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case TRANSACTION_HISTORY_RESPONSE: {
      return {
        ...state,
        response: payload,
      };
    }

    case TRANSACTION_HISTORY_SKIP: {
      return {
        ...state,
        skip: payload,
      };
    }

    case TRANSACTION_HISTORY_LIMIT: {
      return {
        ...state,
        limit: payload,
      };
    }

    case TRANSACTION_HISTORY_LORDER: {
      return {
        ...state,
        loader: payload,
      };
    }

    case TRANSACTION_TYPE: {
      return {
        ...state,
        transactionType: payload,
      };
    }

    case TRANSACTION_TOKENSYMBOL: {
      return {
        ...state,
        transactionTokenSymbol: payload,
      };
    }

    case TRANSACTION_STATUS: {
      return {
        ...state,
        transactionStatus: payload,
      };
    }

    case TRANSAC_SWITCHER: {
      return {
        ...state,
        transacSwitcher: payload,
      };
    }

    case HISTORY_VIEW_DETAILS: {
      return {
        ...state,
        historyViewDetails: payload,
      };
    }

    case SEARCH_VALUE: {
      return {
        ...state,
        searchValue: payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default TransactionReducer;
