import {
  ALLOWED_ACCESS,
  DEFAULT_STATE,
  LOGIN_ERROR_STATE,
  LOGIN_INITIAL_STATE,
  LOGIN_OTP,
  LOGIN_SUCCESS,
  VERIFY_GOOGLE_AUTH,
} from "../types";
import CryptoJS from "crypto-js";
import {SECRET} from "../../utils/secret";

const initialState = {
  loginState: 0,
  forgotState: 0,
  loading: false,
  response: null,
  userData: localStorage.getItem("userData")
    ? JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem("userData") ?? "", SECRET).toString(CryptoJS.enc.Utf8))
    : null,
  googleAuth: null,
  error: null,
  isLoggedIn: localStorage.getItem("isLoggedIn") ?? false,
};

const authReducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case LOGIN_SUCCESS: {
      return {
        ...state,
        loading: false,
        userData: payload,
        isLoggedIn: true,
      };
    }

    case LOGIN_OTP: {
      return {
        ...state,
        loading: false,
        response: payload,
      };
    }

    case ALLOWED_ACCESS: {
      return {
        ...state,
        isLoggedIn: true,
      };
    }

    case LOGIN_INITIAL_STATE: {
      return {
        ...state,
        loading: true,
      };
    }

    case LOGIN_ERROR_STATE: {
      return {
        ...state,
        loading: false,
        error: payload,
      };
    }

    case VERIFY_GOOGLE_AUTH: {
      return {
        ...state,
        loading: false,
        googleAuth: payload,
      };
    }

    case DEFAULT_STATE: {
      return {
        ...state,
        ...initialState,
      };
    }

    default: {
      return state;
    }
  }
};

export default authReducer;
