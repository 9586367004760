import {GET_ALL_ADMINS_DETAILS, EDIT_ADMINS_DETAILS} from "../types";

const initialState = {
  getAllAdminDataState: [],
  editAdminDetailsDataState: {},
};

const adminUserReducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case GET_ALL_ADMINS_DETAILS: {
      return {
        ...state,
        getAllAdminDataState: payload,
      };
    }
    case EDIT_ADMINS_DETAILS: {
      return {
        ...state,
        editAdminDetailsDataState: payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default adminUserReducer;
