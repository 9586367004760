import {JOIN_DISPUTE_ERROR, JOIN_DISPUTE_INITIAL, JOIN_DISPUTE_SUCCESS} from "../types";

const initialState = {
  response: null,
  error: null,
  loading: false,
};

const joinDisputeReducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case JOIN_DISPUTE_SUCCESS: {
      return {
        ...state,
        response: payload,
        loading: false,
      };
    }
    case JOIN_DISPUTE_ERROR: {
      return {
        ...state,
        error: payload,
        loading: false,
      };
    }

    case JOIN_DISPUTE_INITIAL: {
      return {
        ...state,
        loading: payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default joinDisputeReducer;
