import React, {memo} from "react";
import consoleLogo from "../../assets/console-logo.svg";

import OtpInput from "react-otp-input";
import {useDispatch} from "react-redux";
import {forgotPasswordLoader, loginloader} from "../../store/actions/auth";
import "../../styles/login.scss";
import {useHistory} from "react-router-dom";

const ForgotForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const goToOtp = () => {
    dispatch(forgotPasswordLoader(2));
  };

  const goToResetPassword = () => {
    history.push("/");
    dispatch(loginloader(0));
  };
  return (
    <div className="login-container">
      <div className="left-container">
        <img style={logo} src={consoleLogo} alt="icon" />

        <div className="login-content">
          <div className="form-contents">
            <p className="title">Reset Your Password</p>

            <p className="tag" style={{margin: "20px 0 40px"}}>
              Enter the 6-digits code sent to jerr...@gmail.com
            </p>

            <div style={{width: "100%"}}>
              <p className="label">Email Verification Code</p>
              <div style={{display: "flex", alignItems: "center"}}>
                <OtpInput
                  inputStyle={{...style, marginTop: "10px"}}
                  numInputs={3}
                  separator={<span>&nbsp;&nbsp;&nbsp;</span>}
                  placeholder="000"
                  isInputNum={true}
                />
                <span
                  style={{
                    width: "50px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "50px",
                    color: "#D0D5DD",
                  }}
                >
                  -
                </span>
                <OtpInput
                  inputStyle={{...style, marginTop: "10px"}}
                  numInputs={3}
                  separator={<span>&nbsp;&nbsp;&nbsp;</span>}
                  placeholder="000"
                  isInputNum={true}
                />
              </div>
              <p style={forgot}>Resend Code</p>

              <div style={{margin: "30px 0"}}></div>
              <p className="label">Phone Verification Code</p>
              <div style={{display: "flex"}}>
                <OtpInput
                  inputStyle={{...style, marginTop: "10px"}}
                  numInputs={3}
                  separator={<span>&nbsp;&nbsp;&nbsp;</span>}
                  placeholder="000"
                  isInputNum={true}
                />
                <span
                  style={{
                    width: "50px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "50px",
                    color: "#D0D5DD",
                  }}
                >
                  -
                </span>
                <OtpInput
                  inputStyle={{...style, marginTop: "10px"}}
                  numInputs={3}
                  separator={<span>&nbsp;&nbsp;&nbsp;</span>}
                  placeholder="000"
                  isInputNum={true}
                />
              </div>
            </div>
            <p style={forgot}>Resend Code</p>

            <button style={loginNow} color="primary" onClick={() => goToOtp()}>
              Submit
            </button>

            <button style={goBack} color="primary" onClick={() => goToResetPassword()}>
              Back
            </button>
          </div>
        </div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

const logo = {
  width: "150px",
  height: "50px",
  marginLeft: "38px",
  marginTop: "10px",
};

const loginNow = {
  width: "100%",
  background: "#000000",
  color: "#fff",
  borderRadius: "7.31px",
  boxShadow: "none",
  textTransform: "capitalize",
  marginTop: "25px",
  height: "45px",

  fontWeight: "600",
  fontSize: "16px",
  lineHeight: "24px",

  "&:hover": {
    backgroundColor: "#363535",
    boxShadow: "none",
  },

  "&:disabled": {
    border: "1px solid rgb(93, 60, 178, 0.4)",
    backgroundColor: "rgb(93, 60, 178, 0.4)",
    color: "white",
  },
};

const goBack = {
  width: "100%",
  background: "transparent",
  color: "#000",
  fontSize: "0.9rem",
  borderRadius: "7.31px",
  boxShadow: "none",
  textTransform: "capitalize",
  marginTop: "25px",
  height: "40px",
  border: "1px solid #000",

  "&:hover": {
    backgroundColor: "#363535",
    color: "#fff",
    boxShadow: "none",
  },

  "&:disabled": {
    border: "1px solid rgb(93, 60, 178, 0.4)",
    backgroundColor: "rgb(93, 60, 178, 0.4)",
    color: "white",
  },
};

const style = {
  width: "50px",
  height: "50px",
  background: "#fff",
  fontSize: "34px",
  borderRadius: "8px",
  outline: "none",
  paddingLeft: "12px",
  // marginTop: "20px",
  border: "1px solid #D0D5DD",
  textAlign: "left",

  color: "black",

  "&::placeholder": {
    color: "#D0D5DD",
  },
};

const forgot = {
  fontSize: "14px",
  fontWeight: "500",
  lineHeight: "20px",
  color: "#1E2772",
  marginTop: "12px",
  cursor: "pointer",
  textAlign: "right",
  width: "100%",
  textDecoration: "underline",
};

export default memo(ForgotForm);
