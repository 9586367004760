import {
  GET_RESOLVED_TRADE_DEFAULT,
  GET_RESOLVED_TRADE_ERROR,
  GET_RESOLVED_TRADE_INITIAL,
  GET_RESOLVED_TRADE_RESULT,
} from "../types";

const initialState = {
  error: null,
  response: null,
  loading: false,
  skip: 0,
  fskip: 0,
  limit: 10,
};

const resolvedTradeReducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case GET_RESOLVED_TRADE_RESULT: {
      return {
        ...state,
        response: payload,
        loading: false,
      };
    }

    case GET_RESOLVED_TRADE_INITIAL: {
      return {
        ...state,
        loading: payload,
      };
    }
    case GET_RESOLVED_TRADE_ERROR: {
      return {
        ...state,
        error: payload,
        loading: false,
      };
    }

    case GET_RESOLVED_TRADE_DEFAULT: {
      return {
        ...state,
        ...initialState,
      };
    }

    default: {
      return state;
    }
  }
};

export default resolvedTradeReducer;
