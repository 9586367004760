import React, {memo, useState} from "react";
import Button from "@mui/material/Button";
import consoleLogo from "../../assets/console-logo.svg";
import forgotOtp from "../../assets/forgototp.svg";
import emailIcon from "../../assets/email-Icon.svg";
import passwordIcon from "../../assets/password-icon.svg";

import "../../styles/login.scss";
import OtpInput from "react-otp-input";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {goToHome} from "../../store/actions/auth";
import {Loader4} from "../common/Loader";
import {AuthService} from "../../services/auth";
import {FormInput} from "../common/formInput";
import {toast} from "react-toastify";

const ChangePassword = ({setStep, stepData}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [code, setCode] = useState();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [error, setError] = useState("");

  const finalLogin = () => {
    setLoading(true);

    if (password === confirmPassword) {
      AuthService.changePassword({
        email: stepData?.email,
        password: stepData?.password,
        newPassword: password,
        authCode: code,
      })
        .then((res) => {
          if (res?.message === "success") {
            toast("Password changed Successfully");
            // setError("");
            setLoading(false);
            setStep(0);
          }
        })
        .catch((err) => {
          toast("Error " + err?.message);
          setLoading(false);
        });
    } else {
      // setError("password must be equal to confirm password");
      toast("password must be equal to confirm password");
      setLoading(false);
    }
  };

  return (
    <div className="form-contents">
      <p className="title">Oyola Admin Login</p>

      {/* {error && (
        <div style={{border: "1px solid red"}}>
          <p style={{color: "red", border: "1px solid red"}}>{error}</p>
        </div>
      )} */}

      <div style={{width: "100%"}}>
        <FormInput
          margin="45px"
          id="email"
          label={"Password"}
          place={""}
          type={"password"}
          handler={(e) => setPassword(e?.target?.value)}
          trailingIcon={<img src={emailIcon} alt="icon" />}
        />

        <FormInput
          margin="20px"
          id="email"
          label={"Confirm Password"}
          place={"Enter your password"}
          type={"password"}
          handler={(e) => setConfirmPassword(e?.target?.value)}
          trailingIcon={<img src={passwordIcon} alt="icon" />}
        />
      </div>

      <div style={{width: "100%", marginTop: "30px"}}>
        <p className="label">Insert Google Auth Code</p>
        <div style={{display: "flex", alignItems: "center"}}>
          <OtpInput
            inputStyle={{...style, marginTop: "10px"}}
            numInputs={6}
            value={code}
            separator={<span>&nbsp;&nbsp;&nbsp;</span>}
            placeholder="000"
            onChange={(e) => setCode(e)}
            isInputNum={true}
          />
        </div>
      </div>

      <p style={forgot} onClick={() => null}>
        Forgot Password?
      </p>
      <Button variant="contained" sx={loginNow} color="primary" onClick={() => finalLogin()}>
        {loading ? <Loader4 /> : "Login Now"}
      </Button>
    </div>
  );
};

const style = {
  width: "50px",
  height: "50px",
  background: "#fff",
  fontSize: "34px",
  borderRadius: "8px",
  color: "#101828",
  outline: "none",
  paddingLeft: "12px",
  marginTop: "20px",
  border: "1px solid #D0D5DD",
  textAlign: "left",
};

const forgot = {
  fontSize: "14px",
  fontWeight: "500",
  lineHeight: "20px",
  color: "#1E2772",
  marginTop: "20px",
  cursor: "pointer",
  textAlign: "right",
  width: "100%",
  textDecoration: "underline",
};

const loginNow = {
  width: "100%",
  background: "#000000",
  color: "#fff",
  fontSize: "0.9rem",
  borderRadius: "7.31px",
  boxShadow: "none",
  textTransform: "capitalize",
  marginTop: "50px",
  height: "40px",

  "&:hover": {
    backgroundColor: "#363535",
    boxShadow: "none",
  },

  "&:disabled": {
    border: "1px solid rgb(93, 60, 178, 0.4)",
    backgroundColor: "rgb(93, 60, 178, 0.4)",
    color: "white",
  },
};

export default memo(ChangePassword);
