import {combineReducers} from "redux";
import authReducer from "./reducers/auth";
import getUserReducer from "./reducers/getUserReducer";
import disputeTradeReducer from "./reducers/disputeTradeReducer";
import allTradeReducer from "./reducers/allTradeReducer";
import getTradeDataReducer from "./reducers/getTradeDataReducer";
import getTradeChatReducer from "./reducers/getTradeChatsReducer";
import getTradeStatusbyIdReducer from "./reducers/getTradeStatus";
import joinDisputeReducer from "./reducers/joinDisputeReducer";
import submitDisputeReducer from "./reducers/submitDisputeResolutionReducer";
import pendingTradeReducer from "./reducers/pendingTradeReducer";
import resolvedTradeReducer from "./reducers/resolvedTradeReducer";
import disputeTableReducer from "./reducers/disputeTableReducer";
import overviewReducer from "./reducers/overviewReducer";
import notificationReducer from "./reducers/notificationReducer";
import userProfileReducer from "./reducers/userProfile";
import adminUserReducer from "./reducers/adminUsersReducer";
import TransactionReducer from "./reducers/transactionHistoryReducers";

export default combineReducers({
  authReducer,
  getUserReducer,
  disputeTradeReducer,
  allTradeReducer,
  getTradeDataReducer,
  getTradeChatReducer,
  getTradeStatusbyIdReducer,
  joinDisputeReducer,
  submitDisputeReducer,
  pendingTradeReducer,
  resolvedTradeReducer,
  disputeTableReducer,
  overviewReducer,
  notificationReducer,
  userProfileReducer,
  adminUserReducer,
  TransactionReducer,
});
