import React, {memo, useState} from "react";
// import rightImage from "../../assets/polite.png";
import rightImage from "../../assets/idea.svg";
import consoleLogo from "../../assets/console-logo.svg";
// import {useDispatch} from "react-redux";
// import {loginloader} from "../../store/actions/auth";
import "../../styles/login.scss";
// import {useHistory} from "react-router-dom";
import LoginStpes from "./loginSteps";

const LoginForm = () => {
  const [step, setStep] = useState(0);
  const [stepData, setStepData] = useState(0);
  const randomSentence = [
    "Strive not to be a success, but rather to be of value",
    "Another ramdom message that will show again.",
    "Node.js allows you to run JavaScript code outside of a browser window, offering powerful tools to interact",
    "you’re just getting started out with JavaScript development or just want to get an interactive script running as quickly as possible.",
    "functions and can be confusing to work with at first.",
    "rather to be of value",
    "There’s a simpler way",
  ];

  const newSentence = randomSentence[Math.floor(Math.random() * randomSentence.length)];
  // const lastWord = newSentence.split(" ").splice(-1);

  return (
    <div className="login-container">
      <img src={consoleLogo} alt="icon" className="topLogo" />
      <div className="left-container">
        <div className="login-content">
          <LoginStpes step={step} setStep={setStep} stepData={stepData} setStepData={setStepData} />
        </div>
      </div>
      <div className="right-container">
        <div className="innerContainer">
          <p className="sentenceStyle">{newSentence}</p>
          <img src={rightImage} alt="polite" width={"30%"} height={"50%"} />
        </div>
      </div>
    </div>
  );
  // return (
  //   <div className="login-container">
  //     <div className="left-container">
  //       <img style={logo} src={consoleLogo} alt="icon" />
  //       <div></div>
  //       <div></div>
  //       <div className="login-content">
  //         <LoginStpes step={step} setStep={setStep} stepData={stepData} setStepData={setStepData} />
  //       </div>
  //       <div></div>
  //       <div></div>
  //       <div></div>
  //       <div></div>
  //       <div></div>
  //     </div>

  //     <div className="right-container">
  //       <div style={{width: "100%"}}></div>
  //       {/* <img src={rightImage} alt="polite" style={{objectFit: "cover"}} width={"100%"} height={"100%"} /> */}
  //     </div>
  //   </div>
  // );
};

export default memo(LoginForm);
