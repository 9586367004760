import {
  GET_ALL_TRADE_DEFAULT,
  GET_ALL_TRADE_ERROR,
  GET_ALL_TRADE_INITIAL,
  GET_ALL_TRADE_ON_NEXT,
  GET_ALL_TRADE_ON_PREV,
  GET_ALL_TRADE_RESULT,
} from "../types";

const initialState = {
  error: null,
  response: null,
  loading: false,
  skip: 0,
  fskip: 0,
  limit: 10,
};

const allTradeReducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case GET_ALL_TRADE_RESULT: {
      return {
        ...state,
        response: payload,
        loading: false,
      };
    }

    case GET_ALL_TRADE_INITIAL: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_ALL_TRADE_ERROR: {
      return {
        ...state,
        error: payload,
        loading: false,
      };
    }
    case GET_ALL_TRADE_ON_NEXT: {
      return {
        ...state,
        skip: state.skip + 1,
        fskip: state.limit * (state.skip + 1),
        limit: state.limit,
      };
    }
    case GET_ALL_TRADE_ON_PREV: {
      return {
        ...state,
        skip: state.skip - 1,
        fskip: state.limit * (state.skip - 1),
        limit: state.limit,
      };
    }

    case GET_ALL_TRADE_DEFAULT: {
      return {
        ...state,
        ...initialState,
      };
    }

    default: {
      return state;
    }
  }
};

export default allTradeReducer;
