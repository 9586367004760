import {
  GET_USER_ERROR,
  GET_USER_INITIAL,
  GET_USER_RESPONSE,
  GET_ALL_USERS,
  GET_ALL_NON_ADMIN_DATA,
  USER_SKIP,
  USER_LIMIT,
  USER_LOADING,
  GET_ALL_PENDING_USERS,
  GET_ALL_SIDE_BAR_PENDING_USERS,
  GET_ALL_SIDE_BAR_PENDING_USERS_LOADER,
} from "../types";

const initialState = {
  error: null,
  response: null,
  skip: 0,
  limit: 10,
  loading: true,
  getAllNonAdminUsersData: [],
  getAllNonAdminPendingUsersData: [],
  getAllSideBarPendingUsersData: [],
  paginatateNonAdminUsers: {},
  getAllSideBarPendingUsersLoader: false,
};

const getUserReducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case GET_USER_RESPONSE: {
      return {
        ...state,
        response: payload,
        loading: false,
      };
    }

    case GET_USER_INITIAL: {
      return {
        ...state,
        loading: payload,
      };
    }

    case USER_LOADING: {
      return {
        ...state,
        loading: payload,
      };
    }

    case GET_ALL_USERS: {
      return {
        ...state,
        getAllNonAdminUsersData: payload,
      };
    }
    case GET_ALL_PENDING_USERS: {
      return {
        ...state,
        getAllNonAdminPendingUsersData: payload,
      };
    }
    case GET_ALL_SIDE_BAR_PENDING_USERS: {
      return {
        ...state,
        getAllSideBarPendingUsersData: payload,
      };
    }
    case GET_ALL_SIDE_BAR_PENDING_USERS_LOADER: {
      return {
        ...state,
        getAllSideBarPendingUsersLoader: payload,
      };
    }
    case GET_ALL_NON_ADMIN_DATA: {
      return {
        ...state,
        paginatateNonAdminUsers: payload,
      };
    }

    case GET_USER_ERROR: {
      return {
        ...state,
        error: payload,
        loading: false,
      };
    }

    case USER_LIMIT: {
      return {
        ...state,
        limit: payload,
      };
    }

    case USER_SKIP: {
      return {
        ...state,
        skip: payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default getUserReducer;
