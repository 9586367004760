import Button from "@mui/material/Button";
import emailIcon from "../../../assets/email-Icon.svg";
import passwordIcon from "../../../assets/password-icon.svg";
import {FormInput} from "../../common/formInput";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {Loader4} from "../../common/Loader";
import {loginAdminAction} from "../../../store/actions/auth";
import {DEFAULT_STATE} from "../../../store/types";

const TheLoginForm = ({setStep, setStepData}) => {
  const {loading, response, googleAuth, error} = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const theLogin = () => {
    setStepData({email, password});
    dispatch(loginAdminAction({email: email, password}));
  };

  useEffect(() => {
    if (googleAuth && googleAuth?.access === true) {
      setStep(4);
    } else if (googleAuth && googleAuth?.googleAuthSetup === false) {
      setStep(1);
    } else if (googleAuth && googleAuth?.googleAuthSetup === true && googleAuth?.isFirstLoggin === true) {
      setStep(3);
    } else if (response?.message === "code invalid or expired!") {
      setStep(4);
    }

    return () => {
      dispatch({type: DEFAULT_STATE});
    };
  }, [googleAuth, response]);

  return (
    <div className="form-contents">
      <p className="title">Oyola Admin Login</p>

      <div style={{width: "100%"}}>
        <FormInput
          margin="45px"
          id="email"
          label={"Email Address"}
          place={"malik@email.com"}
          type={"email"}
          handler={(e) => setEmail(e?.target?.value)}
          trailingIcon={<img src={emailIcon} alt="icon" />}
        />

        <FormInput
          margin="20px"
          id="email"
          label={"Password "}
          place={"Enter your password"}
          type={"password"}
          handler={(e) => setPassword(e?.target?.value)}
          trailingIcon={<img src={passwordIcon} alt="icon" />}
        />
      </div>

      <p style={forgot} onClick={() => null}>
        Forgot Password?
      </p>
      <Button
        variant="contained"
        sx={loginNow}
        color="primary"
        onClick={() => theLogin()}
        disabled={!(password?.length > 0 && email?.length > 0)}
      >
        {loading ? <Loader4 /> : "Proceed Now"}
      </Button>
    </div>
  );
};

const forgot = {
  fontSize: "14px",
  fontWeight: "500",
  lineHeight: "20px",
  color: "#1E2772",
  marginTop: "20px",
  cursor: "pointer",
  textAlign: "right",
  width: "100%",
  textDecoration: "underline",
};

const loginNow = {
  width: "100%",
  background: "#000000",
  color: "#fff",
  fontSize: "0.9rem",
  borderRadius: "7.31px",
  boxShadow: "none",
  textTransform: "capitalize",
  marginTop: "25px",
  height: "40px",

  "&:hover": {
    backgroundColor: "#363535",
    boxShadow: "none",
  },

  "&:disabled": {
    border: "1px solid rgb(93, 60, 178, 0.4)",
    backgroundColor: "rgb(93, 60, 178, 0.4)",
    color: "white",
  },
};

export default TheLoginForm;
