import {
  GET_DETAILS_OF_WITHDRAWAL_DATA,
  GET_NOTIFICATION_MODAL_CHECKER,
  ALL_NOTIFICATION_LIST,
  NOTIFICATION_NUM_CHECKER,
} from "../types";

const initialState = {
  getdetailsOfWithdrawalDataState: {},
  modalCheckerDataState: {},
  getAllNotificationDataState: [],
  notificationNumCheckerDataState: {},
};

const notificationReducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case GET_DETAILS_OF_WITHDRAWAL_DATA: {
      return {
        ...state,
        getdetailsOfWithdrawalDataState: payload,
      };
    }
    case GET_NOTIFICATION_MODAL_CHECKER: {
      return {
        ...state,
        modalCheckerDataState: payload,
      };
    }
    case ALL_NOTIFICATION_LIST: {
      return {
        ...state,
        getAllNotificationDataState: payload,
      };
    }
    case NOTIFICATION_NUM_CHECKER: {
      return {
        ...state,
        notificationNumCheckerDataState: payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default notificationReducer;
