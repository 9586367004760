import React from "react";
import "../../styles/loginInput.scss";

const FormInput = (props) => {
  return (
    <React.Fragment>
      <div style={{marginTop: props.margin}}>
        <p className="label" style={{marginBottom: "10px"}}>
          {props.label}
        </p>
        <div style={inputContainer}>
          <input
            className="login-input-style"
            id={props.id}
            value={props.value}
            type={props.type}
            placeholder={props.place}
            onChange={(e) => props.handler(e)}
            error={props.error}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
          />

          {props.trailingIcon}
        </div>
      </div>
    </React.Fragment>
  );
};

const inputContainer = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  background: "#f1f3f6",
  borderRadius: "8px",
};

export {FormInput};
