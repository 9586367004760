import {
  SUBMIT_RESOLUTION_DISPUTE_ERROR,
  SUBMIT_RESOLUTION_DISPUTE_INITIAL,
  SUBMIT_RESOLUTION_DISPUTE_SUCCESS,
  FINAL_VEDICT,
} from "../types";

const initialState = {
  final_vedict: null,
  response: null,
  error: null,
  loading: false,
};

const submitDisputeReducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case SUBMIT_RESOLUTION_DISPUTE_SUCCESS: {
      return {
        ...state,
        response: payload,
        loading: false,
      };
    }

    case FINAL_VEDICT: {
      return {
        ...state,
        final_vedict: payload,
      };
    }

    case SUBMIT_RESOLUTION_DISPUTE_ERROR: {
      return {
        ...state,
        error: payload,
        loading: false,
      };
    }

    case SUBMIT_RESOLUTION_DISPUTE_INITIAL: {
      return {
        ...state,
        loading: payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default submitDisputeReducer;
