import {GET_OVERVIEW_METRICS_DATA, GET_SEARCH_USER_DATA} from "../types";

const initialState = {
  getOverViewMetricsDataState: {},
  getSearchUserDataState: [],
};

const overviewReducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case GET_OVERVIEW_METRICS_DATA: {
      return {
        ...state,
        getOverViewMetricsDataState: payload,
      };
    }
    case GET_SEARCH_USER_DATA: {
      return {
        ...state,
        getSearchUserDataState: payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default overviewReducer;
