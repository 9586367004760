import {useEffect, useState} from "react";
import OtpInput from "react-otp-input";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import {AuthService} from "../../../services/auth";
import "../../../styles/googleauthone.scss";
import {SmallLoader} from "../../common/Loader";
const GoogleAuthOne = ({setStep, setStepData, stepData}) => {
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState();
  const [imageData, setimageData] = useState();

  useEffect(() => {
    (function () {
      setLoading(true);
      AuthService.generateGoogleAuthBarcode({email: stepData?.email}).then((res) => {
        if (res.message === "success") {
          setimageData(res?.qrCodeUrl);
        }
        setLoading(false);
      });
    })();
  }, []);

  const confirmGoogleAuth = () => {
    setLoading(true);
    AuthService.confirmGoogleAuth({email: stepData?.email, code})
      .then((res) => {
        if (res.message === "success") {
          toast("Google Auth Setup Complete");
          setLoading(false);
          setStep(2);
        }
      })
      .catch((err) => {
        toast(err?.message);
        setLoading(false);
      });
  };

  return (
    <div className="googleVerificationWrapper">
      {loading ? (
        <div style={{width: "100%", height: "100%", display: "flex", justifyContent: "center"}}>
          <SmallLoader />
        </div>
      ) : (
        <>
          <div className="googleVerificationWrapper__Header">
            <h4>Set up Google Authenticator</h4>
            <p>
              Protect your account and transactions. Please scan this QR code with your Google Authenticator App and
              enter the verification code below.
            </p>
          </div>
          <div className="googleVerificationWrapper__QRcode">
            <img src={imageData ?? ""} alt="qrCode" />
          </div>
          <div className="googleVerificationWrapper__Otp">
            <p>Verification code</p>
            <div className="googleVerificationWrapper__OtpDetails">
              <OtpInput
                value={code}
                inputStyle={{...style, marginTop: "10px"}}
                numInputs={6}
                separator={<span>&nbsp;&nbsp;&nbsp;</span>}
                placeholder="000"
                isInputNum={true}
                onChange={(e) => setCode(e)}
              />
            </div>
          </div>
          <div className="googleVerificationWrapper__DownLayout">
            <div className="googleVerificationWrapper__DownLayoutTextSection">
              <p>{`New to Google Authentication? `} </p>
              <Link> Read our article.</Link>
            </div>
            <div className="googleVerificationWrapper__DownLayoutButtonSection">
              <button onClick={() => setStep(0)} className="googleVerificationWrapper__DownLayoutButtonSectionCancel">
                Cancel
              </button>
              <button
                className="googleVerificationWrapper__DownLayoutButtonSectionConfirm"
                onClick={() => confirmGoogleAuth()}
              >
                Confirm
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const style = {
  width: "50px",
  height: "50px",
  background: "#fff",
  fontSize: "34px",
  color: "#101828",
  borderRadius: "8px",
  outline: "none",
  paddingLeft: "12px",
  marginTop: "20px",
  border: "1px solid #D0D5DD",
  textAlign: "left",
};

export default GoogleAuthOne;
