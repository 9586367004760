import {
  GET_TRADE_CHAT_DEFAULT,
  GET_TRADE_CHAT_ERROR,
  GET_TRADE_CHAT_INITIAL,
  GET_TRADE_CHAT_RESULT,
  GET_TRADE_CHAT_WITHOUT_LOADING,
} from "../types";

const initialState = {
  error: null,
  response: null,
  loading: false,
};

const getTradeChatReducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case GET_TRADE_CHAT_RESULT: {
      return {
        ...state,
        response: payload,
        loading: false,
      };
    }

    case GET_TRADE_CHAT_INITIAL: {
      return {
        ...state,
        loading: payload,
      };
    }

    case GET_TRADE_CHAT_WITHOUT_LOADING: {
      return {
        ...state,
        response: payload,
      };
    }

    case GET_TRADE_CHAT_ERROR: {
      return {
        ...state,
        error: payload,
        loading: false,
      };
    }

    case GET_TRADE_CHAT_DEFAULT: {
      return {
        ...state,
        ...initialState,
      };
    }

    default: {
      return state;
    }
  }
};

export default getTradeChatReducer;
