import React, {memo, useState} from "react";
import Button from "@mui/material/Button";
import "../../styles/login.scss";
import OtpInput from "react-otp-input";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {Loader4} from "../common/Loader";
import {AuthService} from "../../services/auth";
import {toast} from "react-toastify";
import {setAdminDataAction} from "../../store/actions/auth";

const LoginOTP = ({setStep, stepData}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [code, setCode] = useState();
  const [loading, setLoading] = useState(false);

  const finalLogin = () => {
    setLoading(true);

    AuthService.loginAdmin({...stepData, authCode: code})
      .then((res) => {
        setLoading(false);
        if (res?.message === "success") {
          dispatch(setAdminDataAction({data: res, push: history.push}));
        }
      })
      .catch((err) => {
        toast(err.message);
        setLoading(false);
      });
  };

  return (
    <div className="form-contents">
      <>
        <p className="title">Google Verification Code</p>
        <div style={{width: "100%", marginTop: "30px"}}>
          <p className="label">Protect your account and transactions. Please Google Authenticator Code from the App</p>

          <div style={{display: "flex", alignItems: "center"}}>
            <OtpInput
              inputStyle={{...style, marginTop: "10px"}}
              numInputs={6}
              value={code}
              separator={<span>&nbsp;&nbsp;&nbsp;</span>}
              placeholder="000000"
              onChange={(e) => setCode(e)}
              isInputNum={true}
            />
          </div>
        </div>

        <Button
          variant="contained"
          sx={loginNow}
          color="primary"
          onClick={() => {
            // dispatch(goToHome("/home"));
            finalLogin();
          }}
          disabled={code === null || code?.length < 6}
        >
          {loading ? <Loader4 /> : "Login Now"}
        </Button>
      </>
    </div>
  );
};

const style = {
  width: "50px",
  height: "50px",
  background: "#fff",
  fontSize: "34px",
  borderRadius: "8px",
  color: "#101828",
  outline: "none",
  paddingLeft: "12px",
  marginTop: "20px",
  border: "1px solid #D0D5DD",
  textAlign: "left",
};

const forgot = {
  fontSize: "14px",
  fontWeight: "500",
  lineHeight: "20px",
  color: "#1E2772",
  marginTop: "20px",
  cursor: "pointer",
  textAlign: "right",
  width: "100%",
  textDecoration: "underline",
};

const loginNow = {
  width: "100%",
  background: "#000000",
  color: "#fff",
  fontSize: "0.9rem",
  borderRadius: "7.31px",
  boxShadow: "none",
  textTransform: "capitalize",
  marginTop: "50px",
  height: "40px",

  "&:hover": {
    backgroundColor: "#363535",
    boxShadow: "none",
  },

  "&:disabled": {
    border: "1px solid rgb(93, 60, 178, 0.4)",
    backgroundColor: "rgb(93, 60, 178, 0.4)",
    color: "white",
  },
};

export default memo(LoginOTP);
