export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_OTP = "LOGIN_OTP";
export const ALLOWED_ACCESS = "ALLOWED_ACCESS";
export const LOGIN_ERROR_STATE = "LOGIN_ERROR_STATE";
export const LOGIN_INITIAL_STATE = "LOGIN_INITIAL_STATE";
export const VERIFY_GOOGLE_AUTH = "VERIFY_GOOGLE_AUTH";
export const DEFAULT_STATE = "DEFAULT_STATE";
export const LOGOUT = "LOGOUT";
export const LOGIN_STATE = "LOADING_STATE";
export const FORGOT_PASSWORD_STATE = "FORGOT_PASSWORD_STATE";
export const GET_USER_RESPONSE = "GET_USER_RESPONSE";
export const GET_USER_INITIAL = "GET_USER_INITIAL";
export const GET_USER_ERROR = "GET_USER_ERROR";
export const GET_DISPUTE_TRADE_RESULT = "GET_DISPUTE_TRADE_RESULT";
export const GET_DISPUTE_TRADE_INITIAL = "GET_DISPUTE_TRADE_INITIAL";
export const GET_DISPUTE_TRADE_ERROR = "GET_DISPUTE_TRADE_ERROR";
export const GET_DISPUTE_TRADE_DEFAULT = "GET_DISPUTE_TRADE_DEFAULT";
export const GET_PENDING_TRADE_RESULT = "GET_PENDING_TRADE_RESULT";
export const GET_PENDING_TRADE_INITIAL = "GET_PENDING_TRADE_INITIAL";
export const GET_PENDING_TRADE_ERROR = "GET_PENDING_TRADE_ERROR";
export const GET_PENDING_TRADE_DEFAULT = "GET_PENDING_TRADE_DEFAULT";
export const GET_RESOLVED_TRADE_RESULT = "GET_RESOLVED_TRADE_RESULT";
export const GET_RESOLVED_TRADE_INITIAL = "GET_RESOLVED_TRADE_INITIAL";
export const GET_RESOLVED_TRADE_ERROR = "GET_RESOLVED_TRADE_ERROR";
export const GET_RESOLVED_TRADE_DEFAULT = "GET_RESOLVED_TRADE_DEFAULT";
export const GET_TRADE_DATA_RESULT = "GET_TRADE_DATA_RESULT";
export const GET_TRADE_DATA_INITIAL = "GET_TRADE_DATA_INITIAL";
export const GET_TRADE_DATA_ERROR = "GET_TRADE_DATA_ERROR";
export const GET_TRADE_DATA_DEFAULT = "GET_TRADE_DATA_DEFAULT";
export const GET_TRADE_CHAT_RESULT = "GET_TRADE_CHAT_RESULT";
export const GET_TRADE_CHAT_INITIAL = "GET_TRADE_CHAT_INITIAL";
export const GET_TRADE_CHAT_ERROR = "GET_TRADE_CHAT_ERROR";
export const GET_TRADE_CHAT_DEFAULT = "GET_TRADE_CHAT_DEFAULT";
export const GET_TRADE_CHAT_WITHOUT_LOADING = "GET_TRADE_CHAT_WITHOUT_LOADING";
export const GET_ALL_TRADE_RESULT = "GET_ALL_TRADE_RESULT";
export const GET_ALL_TRADE_INITIAL = "GET_ALL_TRADE_INITIAL";
export const GET_ALL_TRADE_ERROR = "GET_ALL_TRADE_ERROR";
export const GET_ALL_TRADE_DEFAULT = "GET_ALL_TRADE_DEFAULT";
export const GET_ALL_TRADE_ON_PREV = "GET_ALL_TRADE_ON_PREV";
export const GET_ALL_TRADE_ON_NEXT = "GET_ALL_TRADE_ON_NEXT";
export const GET_TRADE_STATUS_ERROR = "GET_TRADE_STATUS_ERROR";
export const GET_TRADE_STATUS_INITIAL = "GET_TRADE_STATUS_INITIAL";
export const GET_TRADE_STATUS_SUCCESS = "GET_TRADE_STATUS_SUCCESS";
export const JOIN_DISPUTE_ERROR = "JOIN_DISPUTE_ERROR";
export const JOIN_DISPUTE_INITIAL = "JOIN_DISPUTE_INITIAL";
export const JOIN_DISPUTE_SUCCESS = "JOIN_DISPUTE_SUCCESS";
export const SUBMIT_RESOLUTION_DISPUTE_INITIAL = "SUBMIT_RESOLUTION_DISPUTE_INITIAL";
export const SUBMIT_RESOLUTION_DISPUTE_SUCCESS = "SUBMIT_RESOLUTION_DISPUTE_SUCCESS";
export const SUBMIT_RESOLUTION_DISPUTE_ERROR = "SUBMIT_RESOLUTION_DISPUTE_ERROR";
export const GET_TRADE_DEFAULT = "GET_TRADE_DEFAULT";
export const GET_TRADE_ERROR = "GET_TRADE_ERROR";
export const GET_TRADE_RESULT = "GET_TRADE_RESULT";
export const GET_TRADE_INITIAL = "GET_TRADE_INITIAL";

//OVERVIEW TYPES
export const GET_OVERVIEW_METRICS_DATA = "GET_OVERVIEW_METRICS_DATA";
export const GET_OVERVIEW_DISPUTE_PENDING_DATA = "GET_OVERVIEW_DISPUTE_PENDING_DATA";
export const GET_SEARCH_USER_DATA = "GET_SEARCH_USER_DATA";

//Notification TYPES
export const GET_DETAILS_OF_WITHDRAWAL_DATA = "GET_DETAILS_OF_WITHDRAWAL_DATA";
export const GET_NOTIFICATION_MODAL_CHECKER = "NOTIFICATION_MODAL_CHECKER";
export const ALL_NOTIFICATION_LIST = "ALL_NOTIFICATION_LIST";
export const NOTIFICATION_NUM_CHECKER = "NOTIFICATION_NUM_CHECKER";

export const FINAL_VEDICT = "FINAL_VEDICT";

export const USER_PROFILE_ERROR = "USER_PROFILE_ERROR";
export const USER_PROFILE_LOADER = "USER_PROFILE_LOADER";
export const USER_PROFILE_DETAILS = "USER_PROFILE_DETAILS";

// ADMIN USERS TYPE
export const GET_ALL_ADMINS_DETAILS = "GET_ALL_ADMINS_DETAILS";
export const EDIT_ADMINS_DETAILS = "EDIT_ADMINS_DETAILS";
export const DISPUTE_LIMIT = "DISPUTE_LIMIT";
export const DISPUTE_SKIP = "DISPUTE_SKIP";
export const DISPUTE_TABLE_SKIP = "DISPUTE_TABLE_SKIP";
export const DISPUTE_TABLE_LIMIT = "DISPUTE_TABLE_LIMIT";
export const TRANSACTION_HISTORY_RESPONSE = "TRANSACTION_HISTORY_RESPONSE";
export const TRANSACTION_HISTORY_LIMIT = "TRANSACTION_HISTORY_LIMIT";
export const TRANSACTION_HISTORY_SKIP = "TRANSACTION_HISTORY_SKIP";
export const TRANSACTION_HISTORY_LORDER = "TRANSACTION_HISTORY_LORDER";

// USER PROFILE TYPE
export const GET_ALL_USERS = "GET_ALL_USERS";

// DASHBOARD USERS TYPE
export const GET_ALL_NON_ADMIN_DATA = "GET_ALL_NON_ADMIN_DATA";

export const USER_SKIP = "USER_SKIP";
export const USER_LIMIT = "USER_LIMIT";
export const USER_LOADING = "USER_LOADING";

export const TRANSACTION_TYPE = "TRANSACTION_TYPE";
export const TRANSACTION_TOKENSYMBOL = "TRANSACTION_TOKENSYMBOL";
export const TRANSACTION_STATUS = "TRANSACTION_STATUS";
export const TRANSAC_SWITCHER = "TRANSAC_SWITCHER";
// PENDING USERS
export const GET_ALL_PENDING_USERS = "GET_ALL_PENDING_USERS";

// SIDE MENU
export const GET_OVERVIEW_DISPUTE_PENDING_DATA_SIDE = "GET_OVERVIEW_DISPUTE_PENDING_DATA_SIDE";

export const HISTORY_VIEW_DETAILS = "HISTORY_VIEW_DETAILS";
export const SEARCH_VALUE = "SEARCH_VALUE";

export const GET_ALL_SIDE_BAR_PENDING_USERS = "GET_ALL_SIDE_BAR_PENDING_USERS";
export const GET_ALL_SIDE_BAR_PENDING_USERS_LOADER = "GET_ALL_SIDE_BAR_PENDING_USERS_LOADER";
export const DISPUTE_PENDING_DATA_SIDE_ON_SIDE_MENU = "DISPUTE_PENDING_DATA_SIDE_ON_SIDE_MENU";
