import React, { memo } from "react";
import ForgotForm from "../components/forgot/forgotForm";
import ForgotOTP from "../components/forgot/forgotOTP";
import { useSelector } from "react-redux";

const ForgotPassword = () => {
  const showOTP = useSelector((state) => state.authReducer.forgotState);
  return (
    <React.Fragment>
      {showOTP === 0 && <ForgotForm />}
      {showOTP === 2 && <ForgotOTP />}
    </React.Fragment>
  );
};

export default memo(ForgotPassword);
