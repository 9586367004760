import {toast} from "react-toastify";
import {AuthService} from "../../services/auth";
import {
  LOGIN_STATE,
  LOGIN_ERROR_STATE,
  FORGOT_PASSWORD_STATE,
  LOGIN_SUCCESS,
  VERIFY_GOOGLE_AUTH,
  LOGIN_OTP,
} from "../types";
import {SECRET} from "../../utils/secret";
import CryptoJS from "crypto-js";

export const goToHome = (param) => (dispatch) => {
  dispatch({type: LOGIN_SUCCESS, payload: {user: {}, token: "", isLoggedIn: true}});
  param.push("/home");
};

export const loginloader = (param) => (dispatch) => {
  dispatch({type: LOGIN_STATE, payload: param});
};

export const forgotPasswordLoader = (param) => (dispatch) => {
  dispatch({type: FORGOT_PASSWORD_STATE, payload: param});
};

export const loginAdminAction = (dd) => (dispatch) => {
  return AuthService.getAdminProfile(dd.email)
    .then((res) => {
      if (
        res?.message === "success" &&
        res?.adminProfile?.isFirstLoggin === false &&
        res?.adminProfile?.googleAuthSetup === true
      ) {
        dispatch({type: VERIFY_GOOGLE_AUTH, payload: {...res?.adminProfile, access: true}});
      } else if (
        res?.message === "success" &&
        res?.adminProfile?.isFirstLoggin === false &&
        res?.adminProfile?.googleAuthSetup === false
      ) {
        AuthService.loginAdmin(dd)
          .then((data) => {
            if (data.message === "success") {
              dispatch({type: LOGIN_SUCCESS, payload: data});
            }
          })
          .catch((err) => {
            if (err.message === "admin is not registered! please") {
              dispatch({type: VERIFY_GOOGLE_AUTH, payload: err});
            } else if (err.message === "code invalid or expired!") {
              dispatch({type: LOGIN_OTP, payload: err});
            } else if (err.message === "wrong login details!") {
              toast(err.message);
              dispatch({type: LOGIN_ERROR_STATE, payload: err});
            }
          });
      } else if (
        res?.message === "success" &&
        res?.adminProfile?.isFirstLoggin === true &&
        res?.adminProfile?.googleAuthSetup === false
      ) {
        dispatch({type: VERIFY_GOOGLE_AUTH, payload: res?.adminProfile});
      } else if (
        res?.message === "success" &&
        res?.adminProfile?.isFirstLoggin === true &&
        res?.adminProfile?.googleAuthSetup === true
      ) {
        dispatch({type: VERIFY_GOOGLE_AUTH, payload: res?.adminProfile});
      }
    })
    .catch((err) => {
      dispatch({type: VERIFY_GOOGLE_AUTH, payload: err});

      toast(err.message);
    });
};

export const setAdminDataAction =
  ({data, push}) =>
  (dispatch) => {
    AuthService.getAdminDetails(data)
      .then((res) => {
        if (res?.message === "success") {
          localStorage.setItem("userData", CryptoJS.AES.encrypt(JSON.stringify(res?.adminData), SECRET).toString());
          dispatch({type: LOGIN_SUCCESS, payload: res?.adminData});
          push("/app/overview");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

export const setAdminDataWithTokenAction = () => (dispatch) => {
  AuthService.getAdminDetailsWithToken()
    .then((res) => {
      if (res?.message === "success") {
        // localStorage.setItem("userData", JSON.stringify(res?.adminData));
        dispatch({type: LOGIN_SUCCESS, payload: res?.adminData});
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
