import React, {memo} from "react";
import LoginForm from "../components/login/loginForm";
import LoginOTP from "../components/login/loginOTP";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {useHistory} from "react-router";
import {setAdminDataWithTokenAction} from "../store/actions/auth";

const Login = () => {
  const {push} = useHistory();
  const showOTP = useSelector((state) => state.authReducer.loginState);
  const dispatch = useDispatch();
  const {isLoggedIn, userData} = useSelector((state) => state.authReducer);

  // const performTimeConsumingTask = async () => {
  //   return new Promise((resolve) =>
  //     setTimeout(() => {
  //       if (userData) {

  //       }
  //     }, 100)
  //   );
  // };

  useEffect(() => {
    if (userData) {
      push("/app/overview");
    }
  }, []);
  return (
    <React.Fragment>
      {showOTP === 0 && <LoginForm />}
      {showOTP === 2 && <LoginOTP />}
    </React.Fragment>
  );
};

export default memo(Login);
