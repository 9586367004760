import ChangePassword from "./changePassword";
import TheLoginForm from "./form";
import GoogleAuthOne from "./googleAuth/googleAuthOne";
import GoogleConfirmation from "./googleAuth/googleConfirmation";
import LoginOTP from "./loginOTP";

const LoginStpes = ({step, setStep, stepData, setStepData}) => {
  switch (step) {
    case 0:
      return <TheLoginForm setStep={setStep} setStepData={setStepData} />;

    case 1:
      return <GoogleAuthOne setStep={setStep} stepData={stepData} setStepData={setStepData} />;

    case 2:
      return <GoogleConfirmation setStep={setStep} setStepData={setStepData} />;

    case 3:
      return <ChangePassword setStep={setStep} setStepData={setStepData} stepData={stepData} />;

    case 4:
      return <LoginOTP setStep={setStep} setStepData={setStepData} stepData={stepData} />;

    default:
      break;
  }
};

export default LoginStpes;
